import React from 'react';

import './Navigation.scss';
import NavItem from './NavItem/NavItem';

import { TInjectedWithAuthProps, withAuth } from '../../withAuth';
import { EUserAuthority, TNavigationItem } from 'types';

/**/
type OwnProps = {
  closeAside: () => void;
  isAsideOpen: boolean;
  isLoggedIn: boolean;
  items: TNavigationItem[];
};

type Props = TInjectedWithAuthProps & OwnProps;

/**
 *
 */
class Component extends React.Component<Props> {
  /**/
  renderItem = (item: TNavigationItem) => {
    const { isAsideOpen, isLoggedIn } = this.props;
    return (
      <li className="Navigation__item" key={item.title}>
        <NavItem
          isCollapsed={!isAsideOpen}
          isLoggedIn={isLoggedIn}
          {...(item as any)}
        />
      </li>
    );
  };

  /**/
  render() {
    const { closeAside, items, rights } = this.props;

    const navItems = items.filter(
      ({ authorities }: TNavigationItem) =>
        !authorities ||
        rights.some((r: EUserAuthority) => authorities.includes(r))
    );

    return (
      <ul className="Navigation app-overline-1" onClick={closeAside}>
        {navItems.map(this.renderItem)}
      </ul>
    );
  }
}

/**
 *
 */
const Navigation = withAuth(Component) as any;
export { Navigation, Component as NavigationInner };
