import isequal from 'lodash.isequal';
import message from 'antd/lib/message';
import { push } from 'connected-react-router';

import { ENDPOINTS, ERR_MESSAGE_DURATION, SUB_POINTS } from 'other';
import {
  colorArray,
  DEFAULT_MINI_CARD_PANEL,
  doEntitiesDedupe,
  fetchLocationValue,
  makeFarmFromArea,
  SimpleEntity,
  updateEntityUIState
} from './helpers';
import {
  fetchTrackAction,
  removeTrackDataAction
} from '../tracks/tracksActions';
import {
  filtersBlankState,
  mapEntitiesEmptyState,
  TAction,
  TMapEntitiesState,
  TState
} from 'store';
import { http, SettingsService, THttpResponse } from 'services';
import {
  resetFiltersAction,
  setFiltersAction
} from 'store/filters/filtersActions';
import { showAllProvidersAction } from 'store/provider/providers/providersActions';
import { updateMapOptionsAction } from '../mapOptions/mapOptionsActions';
import { watchLocationsAction } from '../actionMediator';

import {
  EMapEntitiesActions,
  fetchLocationValueSet,
  fetchSet
} from './mapEntitiesConstants';
import {
  EMapLayer,
  EMarkerCategory,
  TDateListItem,
  TDraggableMapEntity,
  TFarmLocation,
  TIdName,
  TLayerPointLocation,
  TLayerPointValue,
  TLocationCoordinates,
  TMapEntityState,
  TProviderAddressShort,
  TVesselLocation
} from 'types';

/**/
export function removeVesselEntriesAction() {
  return (dispatch, getState) => {
    const { mapEntities } = getState() as TState;
    dispatchAction(dispatch, {
      type: EMapEntitiesActions.REMOVE_VESSELS,
      payload: {
        ...mapEntities,
        [EMarkerCategory.VESSEL]: []
      }
    });
  };
}

/**/
export function purgeMapEntitiesAction() {
  return (dispatch, getState) => {
    const { router } = getState() as TState;
    const query = router.location.query;

    dispatch({
      type: EMapEntitiesActions.PURGE_ENTITIES,
      payload: mapEntitiesEmptyState
    });

    query.fleets &&
      dispatch(
        setFiltersAction({
          ...filtersBlankState,
          fleetValue: []
        })
      );
  };
}

/**
 * Checks whether there is a map entity in the route search query. If so, this highlights it,
 * i.e. the entity card pops up, the track is shown. Also works with fleets selection in the query.
 */
export function checkEntitiesAction(isInitial?: boolean) {
  return (dispatch, getState) => {
    const { farmsLocations, mapEntities, router, vesselsLocations } =
      getState() as TState;

    let isLocationRun = false;
    const query = router.location.query;

    if (query.fleets && isInitial) {
      const ids = query.fleets
        .split(',')
        .map((x: string): number | typeof NaN => parseInt(x))
        .filter(Boolean);
      return dispatch(selectFleetsAction(ids));
    }

    if (
      isInitial &&
      (mapEntities[EMarkerCategory.AREA].length > 0 ||
        mapEntities[EMarkerCategory.CAGE].length > 0 ||
        mapEntities[EMarkerCategory.VESSEL].length > 0)
    ) {
      isLocationRun = true;
      dispatch(watchLocationsAction());
      mapEntities[EMarkerCategory.VESSEL].forEach((v: TVesselLocation) =>
        dispatch(fetchTrackAction(v.id))
      );
    }

    if (!query.farm && !query.vessel && isInitial) {
      isLocationRun || dispatch(watchLocationsAction());
      return;
    }

    // Check if there's data to select the entity from.
    if (
      (query.vessel && !vesselsLocations.locations) ||
      (query.farm && !farmsLocations.locations)
    ) {
      // Trigger fetching only if it's not active yet.
      if (
        (query.vessel && !vesselsLocations.isPending) ||
        (query.farm && !farmsLocations.isPending)
      ) {
        // The entity might be out of the filter settings, so we first need to reset the filter.
        dispatch(resetFiltersAction());
        isLocationRun || dispatch(watchLocationsAction());
      }
      return setTimeout(() => dispatch(checkEntitiesAction()), 200);
    }

    if (query.farm) {
      return dispatch(selectFarmAction(parseInt(query.farm)));
    }

    if (query.vessel) {
      dispatch(selectVesselAction(parseInt(query.vessel)));
    }
  };
}

/**/
export function selectFarmAction(farmId: number) {
  return (dispatch, getState) => {
    const { farmsLocations, mapEntities } = getState() as TState;
    let type = EMarkerCategory.CAGE;

    let farm = farmsLocations.locations?.find(
      (f: TFarmLocation) => f.id === farmId
    );
    // If there's no farm, continue lookup among the areas.
    if (!farm) {
      type = EMarkerCategory.AREA;
      farm = makeFarmFromArea(farmsLocations.areas, farmId);
    }

    const sameCondition =
      mapEntities[type].length === 1 && isequal(mapEntities[type][0], farm);

    if (!farm || sameCondition) return;

    dispatch(
      updateMapOptionsAction({
        center: [farm.latitude, farm.longitude] as any,
        zoom: 7
      })
    );
    dispatchAction(dispatch, {
      type: EMapEntitiesActions.SELECT_FARM,
      payload: {
        [type]: doEntitiesDedupe([...mapEntities[type], farm])
      }
    });
  };
}

/**/
export function selectVesselAction(vesselId: number) {
  return (dispatch, getState) => {
    const { mapEntities, vesselsLocations } = getState() as TState;
    const vessel = vesselsLocations.locations?.find(
      (v: TVesselLocation) => v.id === vesselId
    );
    const sameCondition =
      mapEntities[EMarkerCategory.VESSEL].length === 1 &&
      mapEntities[EMarkerCategory.VESSEL][0] === vessel;

    if (sameCondition) return;
    dispatch(fetchTrackAction(vesselId));

    if (!vessel) {
      return dispatch(seekHiddenVesselAction(vesselId));
    }
    dispatch(assignVesselAction(vessel));
  };
}

/**/
export function assignVesselAction(vessel: TVesselLocation) {
  return (dispatch, getState) => {
    const { mapEntities } = getState() as TState;

    dispatch(
      updateMapOptionsAction({
        center: [vessel.latitude, vessel.longitude] as any,
        zoom: 7
      })
    );
    dispatchAction(dispatch, {
      type: EMapEntitiesActions.SELECT_VESSEL,
      payload: {
        [EMarkerCategory.VESSEL]: doEntitiesDedupe([
          ...mapEntities[EMarkerCategory.VESSEL],
          vessel
        ])
      }
    });
    dispatch(addTrackAction(vessel.id));
  };
}

/**
 * If there's no location in the array, we assume that the vessel is hidden, so
 * we'll try to fetch its location individually.
 * @param vesselId
 */
export function seekHiddenVesselAction(vesselId: number) {
  return (dispatch) => {
    const url = `${ENDPOINTS.VESSELS}/${vesselId}${SUB_POINTS.LOCATION}`;
    dispatch(fetchSet.request());

    http
      .send(url)
      .then(({ data }: THttpResponse<TVesselLocation>) => {
        dispatch(fetchSet.success());
        dispatch(
          assignVesselAction({
            ...data,
            isHidden: true
          })
        );
      })
      .catch((e) => dispatch(fetchSet.error(e)));
  };
}

export function selectFleetsAction(ids: number[]) {
  return (dispatch, getState) => {
    const {
      session: { user }
    } = getState() as TState;

    const userFleets = (user ? user.userInfo.fleets : []).map(
      ({ id }: TIdName): number => id
    );

    const fleetsValue = ids.filter((id: number) => userFleets.includes(id));

    dispatch(
      setFiltersAction({
        ...filtersBlankState,
        fleetValue: fleetsValue
      })
    );
    dispatch(watchLocationsAction());
  };
}

/**
 * Toggles HIGHLIGHTED_PROVIDER mode.
 */
export function toggleServiceSelectAction(service?: TProviderAddressShort) {
  return (dispatch, getState) => {
    const { mapEntities } = getState() as TState;

    dispatch({
      type: EMapEntitiesActions.SELECT_SERVICE,
      payload: {
        ...mapEntities,
        [EMarkerCategory.SERVICE]: service ? [service] : []
      }
    });

    if (service) {
      dispatch(
        updateMapOptionsAction({
          center: {
            lat: service.position[0],
            lng: service.position[1]
          } as any,
          layer: EMapLayer.EEZ,
          zoom: 8
        })
      );
      dispatch(showAllProvidersAction());
    }
  };
}

/**
 *
 */
export function addMapEntityAction(
  _entity: TFarmLocation | TVesselLocation,
  type: EMarkerCategory
) {
  return (dispatch, getState) => {
    const { mapEntities } = getState() as TState;

    const entity = {
      ..._entity,
      uiState: { type: type }
    };

    dispatchAction(dispatch, {
      type: EMapEntitiesActions.ADD_ENTITY,
      payload: {
        [type]: [entity, ...mapEntities[type]]
      }
    });

    type === EMarkerCategory.VESSEL &&
      dispatch(minimizeEntityAction(_entity.id, type));
  };
}

/**/
export function removeMapEntityAction(
  entity: SimpleEntity,
  type: EMarkerCategory
) {
  return (dispatch, getState) => {
    const { mapEntities, router } = getState() as TState;
    const query = router.location.query;

    const entitiesUpdate = (mapEntities[type] as any).filter(
      (item) => entity.id !== item.id || entity.providerId !== item.providerId
    );

    if (
      (query.farm &&
        (type === EMarkerCategory.AREA || type === EMarkerCategory.CAGE)) ||
      (query.vessel && type === EMarkerCategory.VESSEL)
    ) {
      dispatch(push({ search: '' }));
    }

    dispatchAction(dispatch, {
      type: EMapEntitiesActions.REMOVE_ENTITY,
      payload: { [type]: entitiesUpdate }
    });

    if (type === EMarkerCategory.VESSEL) {
      dispatch(removeTrackDataAction(entity.id));
    }
  };
}

/**
 *
 */
export function showLocationValueAction(location: TLayerPointLocation) {
  return (dispatch, getState) => {
    const {
      mapOptions: { layer, layerDateLabel, layerInfo, layerValuesMap }
    } = getState() as TState;

    if (
      layer !== EMapLayer.PHYTOPLANKTON &&
      layer !== EMapLayer.SALINITY &&
      layer !== EMapLayer.SEATEMP &&
      layer !== EMapLayer.SURFACETEMP &&
      layer !== EMapLayer.ZOOPLANKTON
    )
      return;

    dispatch(
      fetchLocationValueSet.request({
        [EMarkerCategory.LAYER_VALUE]: []
      })
    );

    const dateItem = (layerInfo[layer]?.dateList || []).find(
      (item: TDateListItem) => item.label === layerDateLabel
    );

    fetchLocationValue(location, layer, layerValuesMap, dateItem.dateTime)
      .then((value: TLayerPointValue) => {
        dispatch(
          fetchLocationValueSet.success({
            [EMarkerCategory.LAYER_VALUE]: value ? [value] : []
          })
        );
      })
      .catch((e) => dispatch(fetchLocationValueSet.error(e)));
  };
}

/**
 *
 */
export const hideLocationValueAction: TAction<
  TMapEntitiesState,
  EMapEntitiesActions
> = {
  type: EMapEntitiesActions.HIDE_LOCATION_VALUE,
  payload: {
    [EMarkerCategory.LAYER_VALUE]: []
  }
};

/**
 *
 */
export function updateDraggableMapEntityAction(
  state: Partial<TMapEntityState>,
  entityId: number,
  type: EMarkerCategory
) {
  return (dispatch, getState) => {
    const { mapEntities } = getState() as TState;
    const update = updateEntityUIState(
      entityId,
      mapEntities[type] as any,
      state
    );
    // .sort((a, b) => (b.id === entityId ? 1 : -1));

    dispatchAction(dispatch, {
      type: EMapEntitiesActions.UPDATE_DRAGGABLE_ENTITY,
      payload: { [type]: update }
    });
  };
}

/**
 *
 */
export function updateEntitiesOnDropAction(entities: TDraggableMapEntity[]) {
  return (dispatch, getState) => {
    const {
      mapEntities: { areas, cages, vessels }
    } = getState() as TState;

    let areasArr = areas;
    let cagesArr = cages;
    let vesselsArr = vessels;

    entities.forEach((entity: TDraggableMapEntity) => {
      if (entity.uiState.type === EMarkerCategory.AREA) {
        areasArr = updateEntityUIState(
          entity.id,
          areasArr,
          entity.uiState
        ) as any;
      }
      if (entity.uiState.type === EMarkerCategory.CAGE) {
        cagesArr = updateEntityUIState(
          entity.id,
          cagesArr,
          entity.uiState
        ) as any;
      } else if (entity.uiState.type === EMarkerCategory.VESSEL) {
        vesselsArr = updateEntityUIState(
          entity.id,
          vesselsArr,
          entity.uiState
        ) as any;
      }
    });

    dispatchAction(dispatch, {
      type: EMapEntitiesActions.UPDATE_ENTRIES_ON_DROP,
      payload: {
        areas: areasArr,
        cages: cagesArr,
        vessels: vesselsArr
      }
    });
  };
}

/**/
export function maximizeEntityAction(entityId: number, type: EMarkerCategory) {
  const uiState = { index: null, panel: null };
  return updateDraggableMapEntityAction(uiState, entityId, type);
}

/**
 *
 * @param entityId
 * @param type
 */
export function minimizeEntityAction(entityId: number, type: EMarkerCategory) {
  return (dispatch, getState) => {
    const { router, mapEntities } = getState() as TState;

    const query = router.location.query;
    const { areas, cages, vessels } = mapEntities;
    const entities = [...areas, ...cages, ...vessels];

    if (
      (query.farm === String(entityId) && type === EMarkerCategory.CAGE) ||
      (query.vessel === String(entityId) && type === EMarkerCategory.VESSEL)
    ) {
      dispatch(push({ search: '' }));
    }

    const targetItem = (mapEntities[type] as any).find(
      (item: TDraggableMapEntity) => item.id === entityId
    );

    const itemsOfDefaultPanel = entities
      .filter(
        (item: TDraggableMapEntity) =>
          item.uiState?.panel === DEFAULT_MINI_CARD_PANEL
      )
      .map((item: TDraggableMapEntity) => {
        const stateUpdate = {
          ...(item.uiState || null),
          index:
            typeof item.uiState?.index === 'number'
              ? item.uiState.index + 1
              : item.uiState.index
        };
        return {
          ...item,
          uiState: stateUpdate
        };
      });

    const item = {
      ...targetItem,
      uiState: {
        index: 0,
        panel: DEFAULT_MINI_CARD_PANEL,
        type: type
      }
    };

    dispatch(updateEntitiesOnDropAction([item, ...itemsOfDefaultPanel]));
  };
}

/**/
export function addTrackAction(vesselId: number) {
  return (dispatch, getState) => {
    const { mapEntities } = getState() as TState;

    const occupiedColors = mapEntities.vessels.map(
      (s: TDraggableMapEntity) => s.uiState?.color
    );
    const spareColor = colorArray.find(
      (color: string) => !occupiedColors.includes(color)
    );

    dispatch(
      updateDraggableMapEntityAction(
        { color: spareColor },
        vesselId,
        EMarkerCategory.VESSEL
      )
    );
  };
}

/**/
export function removeTrackAction(vesselId: number) {
  return updateDraggableMapEntityAction(
    { color: null },
    vesselId,
    EMarkerCategory.VESSEL
  );
}

/**/
export function addUserLocationAction(
  location: TLocationCoordinates
): TAction<TMapEntitiesState, EMapEntitiesActions> {
  return {
    type: EMapEntitiesActions.ADD_LOCATION,
    payload: {
      locations: [location]
    }
  };
}

/**/
export function updateEntriesByTypeAction(
  entries: TDraggableMapEntity[],
  type: EMarkerCategory
) {
  return (dispatch, getState) => {
    const { mapEntities } = getState() as TState;
    const update = {
      ...mapEntities,
      [type]: entries
    };

    if (isequal(mapEntities, update)) return;

    dispatchAction(dispatch, {
      type: EMapEntitiesActions.UPDATE_ENTRIES_BY_TYPE,
      payload: update
    });
  };
}

/**
 * Updates vessel entries after their locations have been updated.
 * @param vessels
 */
export function refreshVesselEntriesAction(vessels: TVesselLocation[]) {
  return (dispatch, getState) => {
    const { mapEntities } = getState() as TState;
    const entriesUpdate = [];

    if (mapEntities.vessels.length > 0) {
      mapEntities.vessels.forEach((l: TVesselLocation) => {
        if (l.isHidden) {
          // Keep hidden vessel intact.
          return entriesUpdate.push(l);
        }

        const updated = vessels.find((v: TVesselLocation) => v.id === l.id);

        if (updated) {
          const target = mapEntities.vessels.find(
            (v: TVesselLocation) => v.id === updated.id
          );

          entriesUpdate.push({
            ...updated,
            ...(target['uiState'] && { uiState: target['uiState'] })
          });
        }
      });
    }

    dispatch(
      updateEntriesByTypeAction(
        entriesUpdate.filter(Boolean),
        EMarkerCategory.VESSEL
      )
    );
  };
}

/**/
export function findUserLocationAction(isActive: boolean) {
  return (dispatch) => {
    if (isActive) {
      return dispatch(updateEntriesByTypeAction([], EMarkerCategory.LOCATION));
    }

    function onSuccess({ coords } /*: GeolocationPosition*/) {
      dispatch(
        addUserLocationAction({
          accuracy: coords.accuracy,
          heading: coords.heading,
          latitude: coords.latitude,
          longitude: coords.longitude,
          speed: coords.speed
        })
      );

      dispatch(
        updateMapOptionsAction({
          center: [coords.latitude, coords.longitude] as any,
          zoom: 11
        })
      );
    }

    function onError(e /*: GeolocationPositionError*/) {
      window.console.error(`ERROR(${e.code}): ${e.message}`);
      message.error(
        'Slow network. Please retry in a while.',
        ERR_MESSAGE_DURATION
      );
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError, {
      enableHighAccuracy: true,
      timeout: 1.2e4,
      maximumAge: 0
    });
  };
}

/**/
export function resetMapEntitiesAction() {
  return (dispatch) => {
    dispatch(push({ search: '' }));

    dispatchAction(dispatch, {
      type: EMapEntitiesActions.RESET_STATE,
      payload: mapEntitiesEmptyState
    });
  };
}

/**/
function dispatchAction(dispatch, action) {
  const entities = SettingsService.readSettings()[SettingsService.MAP_ENTITIES];
  const merged = {
    ...(entities || {}),
    ...action.payload
  };
  const update = {
    ...(merged[EMarkerCategory.CAGE] && {
      [EMarkerCategory.CAGE]: merged[EMarkerCategory.CAGE]
    }),
    ...(merged[EMarkerCategory.VESSEL] && {
      [EMarkerCategory.VESSEL]: merged[EMarkerCategory.VESSEL]
    })
  };
  SettingsService.writeSettings({ [SettingsService.MAP_ENTITIES]: update });
  dispatch(action);
}
