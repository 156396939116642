import React from 'react';
import Collapse from 'antd/lib/collapse';

import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import './Navigation.scss';
import { IconCustom } from '../../common/IconCustom/IconCustom';
import NavGroupHeader from './NavItem2/NavGroupHeader';
import NavItem from './NavItem2/NavItem2';
import { SettingsService } from 'services';

import { EUserAuthority, TNavigationGroup, TNavigationItem } from 'types';
import { TInjectedWithAuthProps, withAuth } from '../../withAuth';

/**/
type OwnProps = {
  closeAside: () => void;
  isAsideOpen: boolean;
  isLoggedIn: boolean;
  items: ReadonlyArray<TNavigationGroup | TNavigationItem>;
};

type Props = RouteComponentProps & TInjectedWithAuthProps & OwnProps;

type State = {
  activeKeys: string[];
};

/**/
const expandIcon = () => (
  <IconCustom className="Navigation__chevron" type="chevron-up" />
);

/**
 *
 */
class Component extends React.Component<Props, State> {
  state: State = {
    activeKeys: SettingsService.readSettings()[SettingsService.NAVIGATION] || []
  };

  /**/
  isActive = ({ path, title }: TNavigationItem): boolean => {
    const { isLoggedIn, location, match } = this.props;
    if (isLoggedIn) return location.pathname === path;
    if (!match) return false;
    return location.state && location.state['id'] === title;
  };

  /**/
  getActiveKey(key: string): string {
    if (!this.props.isAsideOpen) return null;
    return this.state.activeKeys.includes(key) && key;
  }

  /**/
  renderGroup = (item: TNavigationGroup) => {
    const { isAsideOpen, isLoggedIn } = this.props;
    const handle = (keys: string[]) => this.handleCollapse(item.key, !!keys[0]);
    const isActive = item.items.some(this.isActive);

    const items = [
      {
        key: item.key,
        label: (
          <NavGroupHeader
            isActive={isActive}
            isCollapsed={!isAsideOpen}
            isLoggedIn={isLoggedIn}
            item={item}
          />
        ),
        children: (
          <ul className="Navigation">{item.items.map(this.renderItem)}</ul>
        )
      }
    ];

    return (
      <li className="" key={item.title}>
        <Collapse
          activeKey={this.getActiveKey(item.key)}
          bordered={false}
          className="Navigation__group"
          expandIcon={expandIcon}
          ghost={true}
          items={items}
          onChange={handle}
          size="small"
        />
      </li>
    );
  };

  /**/
  renderItem = (item: TNavigationItem) => {
    const { closeAside, isAsideOpen, isLoggedIn } = this.props;
    return (
      <li className="Navigation__item" key={item.title}>
        <NavItem
          closeAside={closeAside}
          isCollapsed={!isAsideOpen}
          isLoggedIn={isLoggedIn}
          item={item}
        />
      </li>
    );
  };

  /**/
  handleCollapse(key: string, isExpanded: boolean): void {
    const set = new Set(this.state.activeKeys);
    isExpanded ? set.add(key) : set.delete(key);
    SettingsService.writeSettings({ [SettingsService.NAVIGATION]: [...set] });
    this.setState({ activeKeys: [...set] });
  }

  /**/
  render() {
    const { closeAside, items, rights } = this.props;

    const navItems = items.filter(
      ({ authorities }: TNavigationItem) =>
        !authorities ||
        rights.some((r: EUserAuthority) => authorities.includes(r))
    );

    return (
      <ul className="Navigation app-overline-1">
        {navItems.map((item: TNavigationGroup | TNavigationItem) =>
          'key' in item ? this.renderGroup(item) : this.renderItem(item)
        )}
      </ul>
    );
  }
}

/**
 *
 */
const NavigationInner = withRouter(Component);
const Navigation = withAuth(NavigationInner) as any;
export { Navigation };
