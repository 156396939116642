import React from 'react';
import classnames from 'classnames';
import Popover from 'antd/lib/popover';

import './PopoverButton.scss';
import { ActionButtonReached, IconButton, IconCustom } from 'components';

/**/
type Props = {
  children: React.ReactElement;
  icon?: string;
  label: string | React.ReactElement;
  title?: string;
};

/**/
type State = { isVisible: boolean };

/**
 *
 */
class PopoverButton extends React.PureComponent<Props, State> {
  state: State = { isVisible: false };

  /**/
  getPopoverHeader = () => {
    const { title } = this.props;

    return (
      title && (
        <header className="PopoverButton__title">
          <h6 className="app-h6">{title}</h6>

          <IconButton
            className="PopoverButton__close"
            icon="close"
            onClick={this.handleClose}
            size="XS"
          />
        </header>
      )
    );
  };

  /**/
  handleClose = () => this.setState({ isVisible: false });

  /**/
  handleFilterVisibility = (isVisible) => this.setState({ isVisible });

  /**/
  renderLabel() {
    const { icon, label } = this.props;
    return (
      <span className="PopoverButton__label">
        {icon && <IconCustom className="PopoverButton__icon" type={icon} />}
        <span className="PopoverButton__text">{label}</span>
      </span>
    );
  }

  /**/
  render() {
    const { isVisible } = this.state;
    const cls = classnames('PopoverButton__button', {
      'PopoverButton__button--visible': isVisible
    });

    return (
      <div className="PopoverButton">
        <div className="PopoverButton__container">
          <Popover
            content={this.props.children}
            title={this.getPopoverHeader}
            trigger="click"
            open={isVisible}
            onOpenChange={this.handleFilterVisibility}
          >
            <ActionButtonReached
              className={cls}
              // iconLeft={icon}
              iconRight="caret-down"
              size="S"
            >
              {this.renderLabel()}
            </ActionButtonReached>
          </Popover>
        </div>
      </div>
    );
  }
}

/**/
export { PopoverButton };
