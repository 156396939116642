import dayjs from 'dayjs';
import 'dayjs/locale/en';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import message from 'antd/lib/message';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { checkSessionAction } from '../store/session/sessionActions';
import { clearSearch } from '../store/search/searchActions';
import {
  BODY_APP_CLASS,
  BODY_DESKTOP_CLASS,
  BODY_IOS_CLASS,
  BODY_IPAD_CLASS,
  BODY_MOBILE_CLASS,
  BODY_TOUCH_CLASS,
  ENV,
  isTouchDevice,
  VERSION
} from '../other';
import { getBuildInfo } from './buildInfo';
import { history, store } from '../store';
import { initializeThemeAction } from '../store/misc/miscActions';
import { isApp, isIPhone, isIOS, isIPad, isMobile } from '../components';
import { refreshFiltersAction } from '../store/filters/filtersActions';
import { scheduleLogout, URL_AUTH_PARAMS } from './auth';

import { ESessionActions } from '../store/session/sessionConstants';

/**/
const styles = [
  'color: white',
  'background-color: ' + ENV.CONSOLE_COLOR,
  'font-size: 14px'
].join(';');

/**
 *
 */
export function runSessionService() {
  getBuildInfo();
  checkCordova();
  checkSpecialCondition();
  doCheck();
  scheduleLogout();
  clearSearch();
  libConfig();

  window.console.log(`%c ${ENV.APP_NAME} v${VERSION} `, styles);
}

/**
 *
 */
function checkCordova() {
  if (isApp()) {
    window.isCordovaDriven = true;
    isIPhone() && document.documentElement.classList.add('Html--app');
  }
}

/**
 *
 */
function checkSpecialCondition() {
  isApp() && document.body.classList.add(BODY_APP_CLASS);
  isIOS() && document.body.classList.add(BODY_IOS_CLASS);
  isIPad() && document.body.classList.add(BODY_IPAD_CLASS);
  isMobile() && document.body.classList.add(BODY_MOBILE_CLASS);

  document.body.classList.add(
    isTouchDevice() ? BODY_TOUCH_CLASS : BODY_DESKTOP_CLASS
  );
}

/**
 *
 * @return {Promise<void>}
 */
async function doCheck() {
  store.dispatch({
    type: ESessionActions.UPDATE_SESSION_REQUEST,
    payload: { isPending: true }
  });
  await store.dispatch(checkSessionAction());
  await store.dispatch(refreshFiltersAction());
  await store.dispatch(initializeThemeAction());
  store.dispatch({
    type: ESessionActions.UPDATE_SESSION,
    payload: { isPending: false }
  });
  // Remove token and username from the URL.
  // Need to wait till the session response comes back. Otherwise routerFollowRedirect action will restore the hash.
  removeTokenFromURL();
}

/**
 *
 */
function removeTokenFromURL() {
  const { origin, pathname, search } = window.location;
  const s = new URLSearchParams(search);
  s.delete(URL_AUTH_PARAMS.AUTH_TOKEN);
  s.delete(URL_AUTH_PARAMS.USER_NAME);

  const refresh =
    s.toString().length === 0
      ? `${origin}${pathname}`
      : `${origin}${pathname}?${s}`;

  window.history.pushState({ path: refresh }, '', refresh);
}

/**
 *
 */
function libConfig() {
  const headerHeight = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--header-height');

  message.config({ top: parseInt(headerHeight) });
  history.listen(() => message.destroy());

  dayjs.extend(updateLocale);
  dayjs.extend(dayOfYear);
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isToday);
  dayjs.extend(isYesterday);
  dayjs.updateLocale('en', { weekStart: 1 });
  dayjs.extend(utc);
  dayjs.extend(weekOfYear);
}
